




















import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'rs-select',
})
export default class RSSelect extends Vue {
  @Prop({required: true}) value: any;
  @Prop({required: false}) placeholder?: string;
  @Prop({required: false}) error?: boolean;

  selectedProxy = '';

  get selected(){
    return this.value;
  }

  set selected(val){
    this.selectedProxy = val;
    this.$emit('input', val);
  }

  get listeners(){
    delete this.$listeners.input;
    return this.$listeners;
  }
}
