















import {Vue, Component, Prop} from 'vue-property-decorator';
import {API_SERVICE} from '@/shared/api/api.service';

@Component({
  name: 'rs-file',
})
export default class RSFile extends Vue {
  @Prop({required: false}) maxSize?: number;


  onFileChange(event: Event) {

    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if(files.length === 0){
      return false;
    }

    const file = files[0];

    if (file && this.maxSize && file.size > this.maxSize) {
      this.$emit('error', `File shouldn't be more than ${this.getSize(this.maxSize)}`);
      return false;

    }

    this.$emit('uploading', true);

    API_SERVICE.core.uploadAvatar(file)
      .then((res) => {
        this.$emit('input', res);
      })
      .catch(() => {
        this.$emit('error', 'Error uploading file, please try later')
      })
      .finally(() => {
        this.$emit('uploading', false);
        this.$emit('finished');
      })
  }

  getSize(size: number) {
    if (size < 1000) {
      return `${size}B`;
    } else if (size < 1000000) {
      return `${size / 1000}KB`;
    }
    return `${size / 1000000}MB`;
  }

  get listeners() {
    delete this.$listeners.input;
    return this.$listeners;
  }

  get attrs() {
    delete this.$attrs.value;
    return this.$attrs;
  }
}
